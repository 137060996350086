import { REGEX } from '@formo/shared';
import { get, isObject } from 'lodash';
import { Download } from 'lucide-react';
import { FC } from 'react';
import { isAddress } from 'viem';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';
import { bytesToMB } from '~/utils/bytesToMB';
import truncateAddress from '~/utils/truncateTokenAddress';

import { Column, TableProps } from '../Table';
import CellWrapper from './CellWrapper';

type TableCellProps = {
  row: Record<string, any>;
  rowKey: string;
  cellRender?: Column['cellRender'];
  showTooltip?: boolean;
  classNames?: TableProps['classNames'];
  onClickDownload?: (filePath: string, downloadName: string) => void;
};

type FileAttributes = { src: string; name: string; size: number };

const MAX_SELECTION_DISPLAY = 3;

function isWalletAddress(value: unknown, rowKey: string): boolean {
  const isEthereumAddress = isAddress(value as string);
  const isSolanaAddress = rowKey === 'solana';
  return isEthereumAddress || isSolanaAddress;
}

function getFileNameAndExtension(filename: string): {
  name: string;
  extension: string;
} {
  if (!filename) return { name: '', extension: '' };

  const array = filename.split('.');
  return { name: array[0] || '', extension: `.${array[1]}` || '' };
}

const TableCell: FC<TableCellProps> = ({
  row,
  rowKey,
  cellRender,
  showTooltip = false,
  classNames,
  onClickDownload,
}) => {
  const value = get(row, rowKey);

  if (isObject(value)) {
    // File type
    if ((value as FileAttributes)?.src) {
      const { name, extension } = getFileNameAndExtension(
        (value as FileAttributes)?.name,
      );
      const content = (
        <button
          className="flex py-1.5 px-2.5 items-center rounded-[4px] border-1 border-black/5 bg-white gap-2.5 max-w-full"
          style={{ boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.06)' }}
          onClick={() => {
            onClickDownload &&
              onClickDownload((value as FileAttributes)?.src, name);
          }}
        >
          <Download size={16} className="min-w-[16px]" />
          <div className="grid grid-cols-12 items-center gap-1.5">
            <div className="flex col-span-8 items-center gap-0">
              <span className="max-w-[75%] text-ellipsis whitespace-nowrap overflow-clip text-sm">
                {name || 'File'}
              </span>
              <span className="whitespace-nowrap overflow-hidden text-sm">
                {extension || ''}
              </span>
            </div>
            <div className="col-span-4 text-xs font-normal text-base-dark-300 overflow-hidden">
              {`${bytesToMB(
                (value as { src: string; name: string; size: number })?.size,
              )} MB`}
            </div>
          </div>
        </button>
      );
      return (
        <CellWrapper content={`${name}${extension}`} showTooltip={showTooltip}>
          <td
            className={cn(
              'text-nowrap px-3 py-2',
              classNames?.td,
              row.__DEFAULT_ROWS__ && 'py-2',
            )}
          >
            {content}
          </td>
        </CellWrapper>
      );
    }

    // Multi choices checkbox
    if (Array.isArray(value)) {
      const tooltipContent = (
        <ul className="list-disc ml-4">
          {(value as Option[]).map((e) => (
            <li>
              <span className="text-sm">{e.label}</span>
            </li>
          ))}
        </ul>
      );

      const displayOptions = (value as Option[]).slice(
        0,
        MAX_SELECTION_DISPLAY,
      );
      const remainCount = (value as Option[]).slice(
        MAX_SELECTION_DISPLAY,
      ).length;

      const content = (
        <div className="flex flex-nowrap gap-2.5 w-full">
          {displayOptions.map((e) => (
            <div
              className={cn(
                'flex-shrink py-1.5 px-2.5 rounded-full border-1 border-black/5 bg-white gap-2.5',
                displayOptions.length === MAX_SELECTION_DISPLAY && 'max-w-1/3',
                displayOptions.length === 2 && 'max-w-1/2',
              )}
            >
              <div className="flex col-span-8 items-center gap-0 max-w-full">
                <span
                  className={cn(
                    'text-ellipsis whitespace-nowrap overflow-clip text-sm max-w-full',
                  )}
                >
                  {e.label}
                </span>
              </div>
            </div>
          ))}
          {remainCount > 0 && (
            <div className="py-1.5 px-2.5 rounded-full border-1 border-black/5 bg-white gap-2.5">
              +{remainCount}
            </div>
          )}
        </div>
      );

      return (
        <CellWrapper content={tooltipContent} showTooltip={showTooltip}>
          <td
            className={cn(
              'text-nowrap px-3 py-2',
              classNames?.td,
              row.__DEFAULT_ROWS__ && 'py-2',
            )}
          >
            {content}
          </td>
        </CellWrapper>
      );
    }
  }

  // For link content
  if (REGEX.LINK.test(value)) {
    const href = ['https://', 'http://'].includes(value as string)
      ? value
      : `https://${value}`;
    const content = (
      <a
        href={href}
        target="_blank"
        className="text-base-dark-450 underline hover:text-primary-600 active:text-primary-650"
      >
        {cellRender ? cellRender(value, { key: rowKey, row }) : String(value)}
      </a>
    );
    return (
      <CellWrapper content={value} showTooltip={showTooltip}>
        <td
          className={cn(
            'text-nowrap px-3 py-2',
            classNames?.td,
            row.__DEFAULT_ROWS__ && 'py-2',
          )}
        >
          {content}
        </td>
      </CellWrapper>
    );
  }

  const tooltipContent = cellRender
    ? cellRender(value, { key: rowKey, row })
    : String(value);

  const displayedContent = isWalletAddress(value, rowKey)
    ? truncateAddress(value)
    : value;

  const content = cellRender
    ? cellRender(displayedContent, { key: rowKey, row })
    : String(displayedContent);

  return (
    <CellWrapper content={tooltipContent} showTooltip={showTooltip}>
      <td
        className={cn(
          'text-nowrap px-3 py-2',
          classNames?.td,
          row.__DEFAULT_ROWS__ && 'py-2',
        )}
      >
        {content}
      </td>
    </CellWrapper>
  );
};

export default TableCell;

import { TooltipContent, TooltipPortal } from '@radix-ui/react-tooltip';
import React from 'react';
import { Tooltip, TooltipTrigger } from '~/components/ui/tooltip';

type TableTooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
};

const TableTooltip: React.FC<TableTooltipProps> = ({ children, content }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          sideOffset={14}
          side="top"
          align="center"
          className="relative"
        >
          <div className="absolute bottom-0 left-1/2 -z-10 h-2.5 w-2.5 -translate-x-1/2 translate-y-1/2 rotate-45 bg-black/80"></div>
          <div className="relative flex max-w-[450px] items-center justify-start overflow-x-auto text-wrap break-words rounded-sm bg-black/80 px-2 py-1.5 text-sm font-medium text-white backdrop-blur-[5px] [box-shadow:0px_3px_4px_0px_rgba(0,0,0,0.09)]">
            {content}
          </div>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};

export default TableTooltip;

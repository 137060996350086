'use client';

import { SelectProps } from '@radix-ui/react-select';
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  RefreshCw,
  Search,
} from 'lucide-react';
import { FC, FormEvent, useState } from 'react';
import { Button } from '~/components/ui/button';
import { Input, InputProps } from '~/components/ui/input';
import { Option } from '~/constants/fields';
import { cn } from '~/lib/utils';

import { DropdownSelect } from '../fields/DropdownSelect';

type SortObject = {
  direction: 'asc' | 'desc';
  sortBy: string;
};

type TableActionBarProps = {
  searchAction?: {
    // eslint-disable-next-line no-unused-vars
    onSearch: (value: string) => void;
    inputProps?: InputProps;
    defaultSearch?: string;
  };
  sortAction?: {
    // eslint-disable-next-line no-unused-vars
    onSort: (sort: SortObject) => void;
    sortOptions: Option[];
    selectProps?: SelectProps;
    defaultSort?: SortObject;
  };
  children?: React.ReactNode;
  refresh?: () => void;
  isLoading?: boolean;
};

const TableActionBar: FC<TableActionBarProps> = ({
  searchAction,
  sortAction,
  children,
  refresh,
  isLoading,
}) => {
  const [sort, setSort] = useState<SortObject>(
    sortAction?.defaultSort || {
      direction: 'asc',
      sortBy: '',
    },
  );

  const onSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchAction?.onSearch(e.currentTarget.search.value);
  };

  const onSort = (props: SortObject) => {
    setSort(props);
    sortAction?.onSort(props);
  };

  return (
    <div className="flex w-full flex-wrap justify-between gap-3">
      <div className="flex flex-wrap gap-3">
        {searchAction && (
          <form className="flex" onSubmit={onSearch}>
            <Input
              {...searchAction.inputProps}
              name="search"
              className={cn(
                'h-8 w-[200px] rounded-r-none',
                searchAction.inputProps?.className,
              )}
              disabled={isLoading}
              defaultValue={searchAction.defaultSearch}
            />
            <Button
              className="h-8 rounded-l-none"
              type="submit"
              disabled={isLoading}
            >
              <Search size={16} />
            </Button>
          </form>
        )}
        {sortAction && (
          <div className="flex items-center gap-3">
            <span className="text-sm">sort by</span>
            <div className="flex">
              <Button
                className="h-8 rounded-r-none border-r-0 px-2.5"
                onClick={() => {
                  const newSort: SortObject = {
                    ...sort,
                    direction: sort.direction === 'asc' ? 'desc' : 'asc',
                  };
                  onSort(newSort);
                }}
                disabled={isLoading}
              >
                {sort.direction === 'asc' ? (
                  <ArrowUpNarrowWide size={16} />
                ) : (
                  <ArrowDownNarrowWide size={16} />
                )}
              </Button>
              <DropdownSelect
                className="h-8 w-[140px] rounded-l-none border-input"
                options={sortAction.sortOptions}
                value={sort.sortBy}
                onChange={(val) => {
                  const newSort = { ...sort, sortBy: val };
                  onSort(newSort);
                }}
                disabled={isLoading}
              />
            </div>
          </div>
        )}
        {refresh && (
          <Button
            variant="ghost"
            className="h-8"
            onClick={refresh}
            disabled={isLoading}
          >
            <RefreshCw
              size={16}
              className={cn(isLoading && 'animate-spin ease-in-out')}
            />
          </Button>
        )}
      </div>
      <div className="flex items-center gap-3">{children}</div>
    </div>
  );
};

export default TableActionBar;

import { FC, Fragment } from 'react';

import TableTooltip from '../TableTooltip';

type CellWrapperProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  showTooltip?: boolean;
};

const CellWrapper: FC<CellWrapperProps> = ({
  children,
  content,
  showTooltip = false,
}) => {
  if (!showTooltip) return <Fragment>{children}</Fragment>;
  if (typeof content === 'undefined') return <Fragment>{children}</Fragment>;
  if (typeof content === 'string' && (content === '-' || !content))
    return <Fragment>{children}</Fragment>;
  return <TableTooltip content={content}>{children}</TableTooltip>;
};

export default CellWrapper;

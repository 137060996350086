'use client';

import { useEffect } from 'react';

type UseClickOutside = (
  // eslint-disable-next-line no-unused-vars
  ref: React.RefObject<HTMLElement>,
  // eslint-disable-next-line no-unused-vars
  callback: () => void,
) => void;

const useClickOutside: UseClickOutside = (ref, callback) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
export default useClickOutside;
